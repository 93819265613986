$(function() {
  "use strict"

  var gTabContainer = $('[data-tab-container]');

  gTabContainer.on('click', '[data-tab-item]', function(){
    var tabIndex = $(this).data('tab-item');

    gTabContainer.find('[data-tab-item]').removeClass('active');
    $(this).addClass('active');
    $('[data-tab-item-container]').hide();
    $('.popover').hide();
    $('[data-tab-item-container="' + tabIndex + '"]').removeClass('hidden').fadeIn();
  });

});
