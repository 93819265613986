import 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/sortable';
import moment from 'moment';
import Rails from 'rails-ujs';
import 'bootstrap';
import 'bootstrap-daterangepicker';
import 'jquery-mask-plugin';

import '../src/utils/overlay';
import '../src/utils/errors';
import '../src/utils/populate';
import '../src/utils/button';
import '../src/utils/array';
import '../src/utils/extend_params';
import '../src/utils/select';

import '../src/utils/jquery-setup';

import '../src/jquery.pageless';

import '../src/modules/list';
import '../src/modules/tooltip';
import '../src/modules/tab';
import '../src/modules/filter';
import '../src/home';

if (process.env.NODE_ENV == 'production') {
  require('../src/hotjar');
  require('../src/analytics');
}

window.moment = moment;

Rails.start();
