$(function() {
  $(window).on('scroll', function() {
    var animateContainers = $('.animate'),
      topOfWindow = $(window).scrollTop(),
      topOffset = 700;

    for (var i = 0; i < animateContainers.length; i++) {
      var currentContainer = $(animateContainers[i]),
        imagePos = currentContainer.offset().top;

      if (imagePos < topOfWindow + topOffset) {
        currentContainer.addClass('animated');
      } else {
        currentContainer.removeClass('animated');
      }
    }
  });
});
