class Overlay {
  constructor(aModal, aOverlay) {
    this.aModal = aModal;
    this.aOverlay = aOverlay;
    this.gOverlay = typeof this.aOverlay === 'undefined' ? $('#overlay') : this.aOverlay;
    this.gModal = typeof this.aModal === 'undefined' ? $('#overlay-content') : this.aModal;
    this.FADE_TIMEOUT = 200;

    this.gOverlay.on('click', '.close-icon', () => {
      this.close();
    });

    this.gOverlay.on('click', '.alert-success .close', () => {
      this.close();
    });
  }

  close() {
    this.gModal.fadeOut(this.FADE_TIMEOUT);
    this.gOverlay.fadeOut(this.FADE_TIMEOUT, () => {
      this.gModal.find('.alert').remove();
    });
    return 0;
  }

  open() {
    this.gOverlay.fadeIn(this.FADE_TIMEOUT);
    this.gModal.fadeIn(this.FADE_TIMEOUT);
    return 0;
  }
}

export default Overlay;
