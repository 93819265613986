Array.prototype.equals = function(array) {
  if (!array) return false;
  if (this.length != array.length) return false;

  for (var i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) return false;
    } else if (this[i] != array[i]) {
      return false;
    }
  }
  return true;
};

export function unique(array) {
  return $.grep(array, function(el, index) {
    return index === $.inArray(el, array);
  });
}

export function deleteIntegerArrayElem(array, el) {
  deleteArrayElem(array, parseInt(el, 10));
}

export function deleteArrayElemFromArray(array, arrayEl) {
  $.map(array, function(item, elIndex) {
    if (typeof item !== 'undefined' && item.equals(arrayEl)) array.splice(elIndex, 1);
  });
}

function deleteArrayElem(array, el) {
  var elIndex = $.inArray(el, array);
  if (elIndex >= 0) array.splice(elIndex, 1);
}
