$(function (){

  $(document).on("click", ".check-box-container", function(e){
    var checkBox        = $(this).find('input[type="checkbox"]');

    checkBox.prop("checked", !checkBox.prop("checked"));
    checkBox.prop("checked") ? $(this).addClass('checked') : $(this).removeClass('checked');
  });

});
