"use strict"

$(function() {

  var gFilterContainer = $('[data-filter-container]'),
      gDataPickerContainer = $('.daterangepicker');

  gDataPickerContainer.on('click', '.range_inputs .applyBtn, .ranges ul li:not(:last):not(:first)', function(e){
    gFilterContainer.find('form').submit();
  });

  gDataPickerContainer.on('click', '.ranges ul li:first', function(e){
    $( ".daterange" ).val("");
    gFilterContainer.find('form').submit();
  });

  gFilterContainer.on('change', '[data-filter-form]', function(e){
    $('#loading').fadeIn();
    if (!$(e.target).is('.daterange')){
      $(this).submit();
    }
    $('#loading').fadeOut();
  });
});
