export function extendParams(aParams, aParamsItens) {
  var filterValue;

  if (typeof aParamsItens !== 'undefined') {
    for (var i = 0; i < aParamsItens.length; i++) {
      filterValue = paramValue($(aParamsItens[i]));

      $.extend(
        aParams,
        jQuery.parseJSON(
          '{ "' +
            filterValue +
            '": "' +
            $(aParamsItens[i])
              .find('[data-select]')
              .val() +
            '" }'
        )
      );
    }
  }

  return aParams;
}

export function paramValue(aParamItem) {
  if (aParamItem.is('[data-multiple-filter-item-async]')) {
    return aParamItem.data('multiple-filter-item-async');
  } else if (aParamItem.is('[data-default-filter-item]')) {
    return aParamItem.data('default-filter-item');
  }

  return aParamItem.data('multiple-filter-item');
}
