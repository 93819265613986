(function($, window) {
  var element;
  var isLoading = false;
  var loader;
  var namespace = '.pageless';
  var SCROLL = 'scroll' + namespace;
  var RESIZE = 'resize' + namespace;
  var settings = {};
  var container;
  var $container;

  $.pageless = function(opts) {
    if ($.isFunction(opts)) {
      opts.call();
    } else {
      init(opts);
    }
  };

  $.pagelessReset = function() {
    var inited = settings.inited;

    settings = {
      container: window,
      currentPage: 1,
      distance: 100,
      pagination: '.pagination',
      params: {},
      url: location.href,
      loaderImage: '/images/load.gif',
      method: 'get'
    };

    container = settings.container;
    $container = $(container);

    if (inited) {
      stopListener();
    }

    // if there is a afterStopListener callback we call it
    if (settings.end) {
      settings.end.call();
    }
  };

  $.pagelessCurrentPage = function() {
    return settingOrFunc('currentPage');
  };

  var loaderHtml = function() {
    return settings.loaderHtml || '<div id="loading-pageless">' + '<div class="pageless-msg"></div>' + '</div>';
  };

  // settings params: totalPages
  function init(opts) {
    $.pagelessReset();

    if (!settings.inited) {
      settings.inited = true;
    }

    if (opts) {
      $.extend(settings, opts);
    }

    // for accessibility we can keep pagination links
    // but since we have javascript enabled we remove pagination links
    if (settings.pagination) {
      $(settings.pagination).remove();
    }

    // start the listener
    startListener();
  }

  $.fn.pageless = function(opts) {
    var $el = $(this);
    var $loader = $(opts.loader, $el);

    init(opts);
    element = $el;

    // loader element
    if (opts.loader && $loader.length) {
      loader = $loader;
    } else {
      loader = $(loaderHtml());
      $el.append(loader);
      // if we use the default loader, set the message
      if (!opts.loaderHtml) {
        $('#loading-pageless .pageless-msg')
          .html(opts.loaderMsg)
          .css(opts.msgStyles || {});
      }
    }
  };

  //
  function loading(bool) {
    isLoading = bool;
    if (loader) {
      if (isLoading) {
        loader.fadeIn('normal');
      } else {
        loader.fadeOut('normal');
      }
    }
  }

  // distance to end of the container
  function distanceToBottom() {
    return container === window
      ? $(document).height() - $container.scrollTop() - $container.height()
      : $container[0].scrollHeight - $container.scrollTop() - $container.height();
  }

  function settingOrFunc(name) {
    var ret = settings[name];
    return $.isFunction(settings[name]) ? ret() : ret;
  }

  function stopListener() {
    if ($container) {
      $container.unbind(namespace);
    }
  }

  // * bind a scroll event
  // * trigger is once in case of reload
  function startListener() {
    $container.bind(SCROLL + ' ' + RESIZE, watch).trigger(SCROLL);
  }

  function reachBottom() {
    if (document.body.scrollHeight - document.body.clientHeight == document.body.scrollTop) {
      return true;
    }
    return false;
  }

  function watch() {
    var currentPage = settingOrFunc('currentPage');
    var totalPages = settingOrFunc('totalPages');

    // listener was stopped or we've run out of pages
    if (totalPages <= currentPage) {
      if (!$.isFunction(settings.currentPage) && !$.isFunction(settings.totalPages)) {
        stopListener();
        // if there is a afterStopListener callback we call it
        if (settings.end) {
          settings.end.call();
        }
      }
      return;
    }

    // if slider past our scroll offset, then fire a request for more data
    if (!isLoading && reachBottom()) {
      var url = settingOrFunc('url');
      var requestParams = settingOrFunc('params');

      loading(true);

      // move to next page
      currentPage++;
      if (!$.isFunction(settings.currentPage)) {
        settings.currentPage = currentPage;
      }

      // set up ajax query params
      $.extend(requestParams, { page: currentPage });
      // finally ajax query
      $.ajax({
        data: requestParams,
        dataType: 'html',
        url: url,
        method: settings.method,
        success: function(data) {
          if ($.isFunction(settings.scrape)) {
            data = settings.scrape(data);
          }
          if (loader) {
            loader.before(data);
          } else {
            element.append(data);
          }
          loading(false);
          // if there is a complete callback we call it
          if (settings.complete) {
            settings.complete.call();
          }
        }
      });
    }
  }
})(jQuery, window);
