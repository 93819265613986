"use strict"

$(function() {

  var gListContainer  = $('.list');

  gListContainer.on('mouseenter', '.small-delete-btn', function(){
    $(this).closest('li').addClass("list-hover");
  });

  gListContainer.on('mouseleave', '.small-delete-btn', function(){
    $(this).closest('li').removeClass("list-hover");
  });

});

