export function appendResults(aResults, aSelect, aOptions, aValue) {
  if (aResults !== null) {
    for (var i = 0; i < aResults.length; i++) {
      var result = aResults[i],
        customName,
        selectData;

      if (typeof aOptions !== 'undefined') customName = aOptions.name;

      customName = typeof customName === 'undefined' ? 'name' : customName;

      selectData = {
        id: result.id,
        name: result[customName],
        dataAttr: result.dataAttr,
        dataValue: result.dataValue
      };

      if (typeof aSelect !== 'undefined') {
        aSelect.append(createOption(selectData));
        aSelect.val(aValue);
      }
    }
  }
}

export function removeOptions(aSelect) {
  aSelect.find('option:not([value=""])').remove();
}

export function removeAllOptions(aFilter, aSelect) {
  aFilter.nextAll('[data-filter-item]').each(function() {
    removeOptions($(this));
  });

  aSelect.val('');
}

export function removeAllSelectOptions(aSelect) {
  removeOptions(aSelect);
  aSelect.val('');
}

export function createOption(aData) {
  var option = $('<option></option>');
  option.val(aData.id).text(aData.name);
  if (aData.dataAttr !== undefined) {
    for (var i = 0; i < aData.dataAttr.length; i++) option.attr(aData.dataAttr[i], aData.dataValue[i]);
  }

  return option;
}

export function checkSelects(aItens) {
  for (var i = 0; i < aItens.length; i++) {
    var select = $(aItens[i]).find('[data-select]');

    if (select.val() == '') {
      return false;
      break;
    }
  }
  return true;
}
