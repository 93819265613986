jQuery(function($) {
  $.datepicker.regional['pt-BR'] = {
    closeText: 'Fechar',
    prevText: '&#x3c;Anterior',
    nextText: 'Pr&oacute;ximo&#x3e;',
    currentText: 'Hoje',
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Mar&ccedil;o',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    dayNames: [
      'Domingo',
      'Segunda-feira',
      'Ter&ccedil;a-feira',
      'Quarta-feira',
      'Quinta-feira',
      'Sexta-feira',
      'Sabado'
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    weekHeader: 'Sm',
    dateFormat: 'dd/mm/yy',
    firstDay: 0,
    showMonthAfterYear: false,
    yearSuffix: ''
  };

  $.datepicker.setDefaults($.datepicker.regional['pt-BR']);
});

$.fn.initializeDateTimePicker = function() {
  $(this).daterangepicker({
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true,
    locale: {
      format: 'DD/MM/YYYY HH:mm',
      separator: ' - ',
      applyLabel: 'Aplicar',
      cancelLabel: 'Cancelar',
      weekLabel: 'W',
      customRangeLabel: 'Escolher intervalo',
      firstDay: moment.localeData().firstDayOfWeek(),
      daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ]
    }
  });
};

$(document).ready(function() {
  $('.datepicker').datepicker({
    beforeShow: function(field, picker) {
      picker.dpDiv.css({
        marginLeft: field.offsetWidth - 215 + 'px'
      });
    }
  });

  $('.daterange').daterangepicker({
    opens: 'left',
    ranges: {
      Hoje: [moment(), moment()],
      Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Últimos 7 dias': [moment().subtract(6, 'days'), moment()],
      'Últimos 30 dias': [moment().subtract(29, 'days'), moment()]
    },

    locale: {
      daysOfWeek: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      format: 'DD/MM/YYYY',
      customRangeLabel: 'Escolher intervalo',
      applyLabel: 'Aplicar',
      cancelLabel: 'Cancelar'
    }
  });

  $('.daterange').val($('.daterange').data('range'));
  $('.number').mask('000000000');
  $('.date').mask('00/00/0000', { clearIfNotMatch: true });
  $('.time').mask('00:00:00');
  $('.date_time').mask('00/00/0000 00:00:00');
  $('.cep').mask('00000-000', { clearIfNotMatch: true });
  $('.phone').mask('0000-0000');
  $('.phone_with_ddd').mask('(00) 0000-0000');
  $('.mixed').mask('AAA 000-S0S');
  $('.cpf').mask('000.000.000-00', { reverse: true });
  $('.money').mask('#.##0,00', { reverse: true });
  $('.year').mask('0000');
});
