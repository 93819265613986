export function showSuccess(aData, aPrependDiv) {
  var div = $(
    '<div class="alert alert-success"><a class="close" data-dismiss="alert" href="#" aria-hidden="true">&times;</a></div>'
  );
  div = appendMessages(div, aData.message);
  $(aPrependDiv).prepend(div);
}

export function showErrors(aData, aPrependDiv) {
  var div = $(
    '<div data-alert class="alert alert-danger"><a class="close" data-dismiss="alert" href="#" aria-hidden="true">&times;</a></div>'
  );
  $.each(aData.errors, function(i) {
    $.each(aData.errors[i], function(key, val) {
      div = appendMessages(div, val);
    });
  });
  $(aPrependDiv).prepend(div);
}

export function appendMessages(aDiv, aError) {
  $('.call').empty();
  $(document)
    .find('.alert-danger')
    .css('display', 'none');
  $(document)
    .find('.alert-success.fixed-alert')
    .fadeOut(100);
  $(document)
    .find('.alert-success')
    .css('display', 'none');
  $('header, .main, header nav, .content').removeClass('alert-header');
  var item = $('<div">' + aError + '</div>');
  aDiv.append(item);
  return aDiv;
}

$(document).on('click', '.close', function() {
  $(this)
    .parent()
    .fadeOut();
});

$('.alert').on('click', '.fixed-close', function() {
  $(this)
    .parent()
    .fadeOut(100);
  // TODO update binds after 'layout merge'
  $('header, .main, header nav, .content').removeClass('alert-header');
});
